@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,300);
@import url(https://fonts.googleapis.com/css2?family=Italiana&display=swap);

.landing{
    font-size: 3.5vw;
    position: absolute;
    text-transform: capitalize;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    font: 'Montserrat';
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
}

h1 span {
    font-size: 1.7vw;
    display: block;
    margin-top: 1em;
    font-weight: 300;
    color: #FFA500;
    animation: showup 4s;
    letter-spacing: 3.5px;
}

body {
    padding: 0;
    margin: 0;
    background-color: #fff;
}

.hero {
    display: inline-block;
    width: 100%;
    height: 100vh;
    position: relative;
}

.diagonal-hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to top, #0979f9, #135ed9, #1445b8, #102c99, #07127a);
    z-index: -1;
    /* clip-path: polygon(0 0, 100% 0, 100% 77%, 0 100%); */
    /* clip-path: ellipse(100% 55% at 48% 44%); */
    clip-path: polygon(100% 0, 100% 55%, 50% 100%, 0 55%, 0 0);
    /* clip-path: circle(65.3% at 50% 2%); */
}



@media (max-width: 768px) {

    h1 {
        font-size: 12vw !important;
        white-space: normal !important;
    }

    h1 span {
    font-size: 5vw;
    /* white-space: nowrap !important; */
    }
    .diagonal-hero-bg{
        height: 100vh;
    }
        .cc {
            width: 100vw !important;
            margin: 0 !important;
            padding: 0 !important;
            font-size: 1em;
        }
        .wrapper {
            /* background: linear-gradient(60deg, #420285, #08BDBD); */
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0%;
        }
}

@keyframes showup {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.wu{
    /* background: #1F4690; */
}

.slider {
    height: 60vh;
    /* background-color: #293132; */
    text-align: center;
    position: relative;
    animation: slideColor 10s forwards infinite;
}

.caption {
    line-height: 100px;
    font-size: 60px;
    color: #1F4690;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-shadow: 0px 5px 5px rgba(0, 0, 0, .25);
    margin-left: -300px;
}

.text-box {
    display: inline-block;
    position: relative;
}

.text-box div {
    display: inline-block;
    position: absolute;
    top: -200px;
    transform: rotateX(-90deg);
    opacity: 0;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, .25);
    animation-timing-function: ease;
}

.text-box div:nth-child(1) {
    animation: rollDown 13s forwards infinite;
}

.text-box div:nth-child(2) {
    animation: rollDown2 13s forwards infinite;
}

.text-box div:nth-child(3) {
    animation: rollDown3 13s forwards infinite;
}
.text-box div:nth-child(4) {
    animation: rollDown4 13s forwards infinite;
}

@keyframes rollDown {
    0% {
        top: -200px;
        transform: rotateX(-90deg);
    }

    8.33% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    16.66% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    25% {
        top: 50px;
        transform: rotateX(30deg);
        opacity: 0;
    }
}

@keyframes rollDown2 {
    25% {
        top: -200px;
        transform: rotateX(-90deg);
    }

    33.33% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    41.66% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    50% {
        top: 50px;
        transform: rotateX(30deg);
        opacity: 0;
    }
}

@keyframes rollDown3 {
    50% {
        top: -200px;
        transform: rotateX(-90deg);
    }

    58.33% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    66.66% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    75% {
        top: 50px;
        transform: rotateX(30deg);
        opacity: 0;
    }
}

@keyframes rollDown4 {
    75% {
        top: -200px;
        transform: rotateX(-90deg);
    }

    83.33% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    91.66% {    
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    99% {
        top: 50px;
        transform: rotateX(30deg);
        opacity: 0;
    }
}

/* @keyframes slideColor {
    0% {
        background-color: #387780;
    }

    33% {
        background-color: #3f88c5;
    }

    66% {
        background-color: #588b8b;
    }

    100% {
        background-color: #387780;
    }
} */

.wrapper {
    /* background: linear-gradient(60deg, #420285, #08BDBD); */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin:25% 25%;
}

.carousel {
    position: relative;
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.carousel__item {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    /* padding: 0 12px; */
    opacity: 0;
    filter: drop-shadow(0 2px 2px #555);
    will-change: transform, opacity;
    -webkit-animation: carousel-animate-vertical 27s linear infinite;
    animation: carousel-animate-vertical 27s linear infinite;
}

.carousel__item:nth-child(1) {
    -webkit-animation-delay: calc(3s * -1);
    animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
    -webkit-animation-delay: calc(3s * 0);
    animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
    -webkit-animation-delay: calc(3s * 1);
    animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
    -webkit-animation-delay: calc(3s * 2);
    animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
    -webkit-animation-delay: calc(3s * 3);
    animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
    -webkit-animation-delay: calc(3s * 4);
    animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
    -webkit-animation-delay: calc(3s * 5);
    animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
    -webkit-animation-delay: calc(3s * 6);
    animation-delay: calc(3s * 6);
}

.carousel__item:last-child {
    -webkit-animation-delay: calc(-3s * 2);
    animation-delay: calc(-3s * 2);
}

.carousel__item-head {
    border-radius: 50%;
    background-color: #d7f7fc;
    width: 90px;
    height: 90px;
    padding: 14px;
    position: relative;
    margin-right: -45px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}

.carousel__item-body {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px 20px 16px 70px;
}

.title {
    text-transform: uppercase;
    font-size: 20px;
    margin-top: 10px;
}

@-webkit-keyframes carousel-animate-vertical {
    0% {
        transform: translateY(100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
    }

    3%,
    11.1111111111% {
        transform: translateY(100%) scale(0.7);
        opacity: 0.4;
        visibility: visible;
    }

    14.1111111111%,
    22.2222222222% {
        transform: translateY(0) scale(1);
        opacity: 1;
        visibility: visible;
    }

    25.2222222222%,
    33.3333333333% {
        transform: translateY(-100%) scale(0.7);
        opacity: 0.4;
        visibility: visible;
    }

    36.3333333333% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: visible;
    }

    100% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes carousel-animate-vertical {
    0% {
        transform: translateY(100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
    }

    3%,
    11.1111111111% {
        transform: translateY(100%) scale(0.7);
        opacity: 0.4;
        visibility: visible;
    }

    14.1111111111%,
    22.2222222222% {
        transform: translateY(0) scale(1);
        opacity: 1;
        visibility: visible;
    }

    25.2222222222%,
    33.3333333333% {
        transform: translateY(-100%) scale(0.7);
        opacity: 0.4;
        visibility: visible;
    }

    36.3333333333% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: visible;
    }

    100% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
    }
}