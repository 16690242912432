#contact {
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #0979f9, #135ed9, #1445b8, #102c99, #07127a);
}

.section-header {
    text-align: center;
    margin: 0 auto;
    padding: 40px 0;
    font: 300 60px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.contact-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    max-width: 840px;
}

/* Left contact page */
.form-horizontal {
    /*float: left;*/
    max-width: 400px;
    font-weight: 400;
}

.form-control,
textarea {
    max-width: 400px;
    background-color: #000;
    color: #fff;
    letter-spacing: 1px;
}

.send-button {
    margin-top: 15px;
    height: 34px;
    width: 400px;
    overflow: hidden;
    transition: all .2s ease-in-out;
}

.alt-send-button {
    width: 400px;
    height: 34px;
    transition: all .2s ease-in-out;
}

.send-text {
    display: block;
    margin-top: 10px;
    font: 700 12px;
    letter-spacing: 2px;
}

.alt-send-button:hover {
    transform: translate3d(0px, -29px, 0px);
}

/* Begin Right Contact Page */
.direct-contact-container {
    max-width: 450px;
}

/* Location, Phone, Email Section */
.contact-list {
    list-style-type: none;
    margin-left: -30px;
    padding-right: 20px;
}

.list-item {
    line-height: 3;
    color: #fff;
}

.contact-text {
    font: 300 18px;
    letter-spacing: 1.9px;
    color: #fff !important;
}

/* .place {
    margin-left: 62px;
}

.phone {
    margin-left: 56px;
}

.gmail {
    margin-left: 53px;
} */

.contact-text a {
    color: #fff;
    text-decoration: none;
    transition-duration: 0.2s;
}

.contact-text a:hover {
    color: #bbb;
    text-decoration: none;
}

@media screen and (max-width: 569px) {

    .direct-contact-container,
    .form-wrapper {
        float: none;
        margin: 0 auto;
    }

    .form-control,
    textarea {

        margin: 0 auto;
    }


    .name,
    .email,
    textarea {
        width: 280px;
    }

    .direct-contact-container {
        margin-top: 60px;
        max-width: 280px;
    }

}

@media screen and (max-width: 410px) {
    .send-button {
        width: 99%;
    }
}